// Product set selector
$product-set-selector-navigation-margin: rem-calc(0 0 30) !default;
$product-set-selector-navigation-margin--large: rem-calc(0 0 50) !default;
$product-set-selector-actions-margin: rem-calc(30 0 0) !default;
$product-set-selector-actions-margin--large: rem-calc(40 0 0) !default;
// Dark theme styles
$product-set-selector-dark-modal-subtitle: color(light) !default;
$product-set-selector-dark-figure-title-color: color(light) !default;
$product-set-selector-dark-figure-title-hover-color: color(element-background) !default;
$product-set-selector-dark-figure-title-selected-color: color(light) !default;
$product-set-selector-dark-figure-title-selected-text-decoration: underline !default;
@mixin lora-components-product-set-selector {
    .c-product-set-selector__navigation {
        margin: $product-set-selector-navigation-margin;

        @include breakpoint(large) {
            margin: $product-set-selector-navigation-margin--large;
        }
    }

    .c-product-set-selector__actions {
        margin: $product-set-selector-actions-margin;

        @include breakpoint(large) {
            margin: $product-set-selector-actions-margin--large;
        }
    }

    .c-product-set-selector {
        &.m-dark {
            .c-modal__subtitle {
                color: $product-set-selector-dark-modal-subtitle;
            }

            .c-figure__title {
                color: $product-set-selector-dark-figure-title-color;
            }

            .c-figure.m-link:not(.m-selected):hover {
                .c-figure__title {
                    color: $product-set-selector-dark-figure-title-hover-color;
                }
            }

            .c-figure.m-selected {
                .c-figure__title {
                    color: $product-set-selector-dark-figure-title-selected-color;
                    text-decoration: $product-set-selector-dark-figure-title-selected-text-decoration;
                }
            }
        }
    }
}
