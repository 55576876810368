@import "../button";
$product-add-bag-price-after-margin: rem-calc(0 5) !default;
$product-add-bag-font-size: rem-calc(14) !default;
$product-add-bag-font-size--large: $product-add-bag-font-size !default;
$product-add-bag-height: rem-calc(40) !default;
$product-add-bag-height--large: $product-add-bag-height !default;
$product-add-bag-letter-spacing--large: 0 !default;
$product-add-bag-side-padding: rem-calc(5) !default;
$product-add-bag-price-capacity-flex: 1 0 auto !default;
$product-add-bag-price-capacity-color: inherit !default;
$product-add-bag-price-capacity-padding: rem-calc(0 5) !default;
$product-add-bag-price-capacity-font-size: rem-calc(11) !default;
$product-add-bag-padding: rem-calc(5) !default;
$product-add-bag-border: null !default;

@mixin lora-components-product-add-bag {
    .c-product-add-bag {
        @include lora-helper-button;
        @include lora-helper-button-expand;

        font-size: $product-add-bag-font-size--large;
        height: $product-add-bag-height--large;
        letter-spacing: $product-add-bag-letter-spacing--large;
        padding: $product-add-bag-padding;

        @include breakpoint(medium down) {
            font-size: $product-add-bag-font-size;
            height: $product-add-bag-height;
            padding-#{$global-left}: $product-add-bag-side-padding;
            padding-#{$global-right}: $product-add-bag-side-padding;
            border: $product-add-bag-border;
        }
    }

    .c-product-add-bag__price {
        .c-product-add-bag__price-after {
            display: inline-block;
            margin: $product-add-bag-price-after-margin;
        }

        .c-price-capacity {
            flex: $product-add-bag-price-capacity-flex;
            color: $product-add-bag-price-capacity-color;
            padding: $product-add-bag-price-capacity-padding;
            font-size: $product-add-bag-price-capacity-font-size;
        }
    }

    .c-product-add-bag__form {
        @include breakpoint(medium down) {
            flex-grow: 1;
        }
    }
}
