$product-detail-image-mosaic-item-padding: rem-calc(0 0 35 20);
$product-detail-image-mosaic-item-first-margin: 0 auto rem-calc(20);
$product-detail-image-mosaic-services-primary-bottom: rem-calc(5);
$product-detail-image-mosaic-services-secondary-padding: 0;
$product-detail-image-mosaic-services-margin: rem-calc(2 0 0);
$product-detail-image-mosaic-services-justify-content: center;
$product-detail-image-mosaic-services-spacing: rem-calc(2);
$product-detail-image-mosaic-services-secondary-margin: rem-calc(8);
$product-detail-image-mosaic-padding: rem-calc(18);
$product-detail-image-badge-large-top: 0;

// Horizontal carousel
$product-detail-image-alternatives-horizontal-max-width--large: rem-calc(670);

// 360 View
$product-detail-image-view360-right: rem-calc(15);

// Horizontal carousel
$product-detail-image-alternatives-horizontal-margin: rem-calc(30) auto;
$product-detail-image-alternatives-horizontal-margin--large: $product-detail-image-alternatives-horizontal-margin;
$product-detail-image-alternatives-horizontal-padding: rem-calc(0 25);

// Horizontal carousel small
$product-detail-image-alternatives-horizontal-small-max-width: rem-calc(320);
$product-detail-image-alternatives-horizontal-small-margin: rem-calc(20) auto;
$product-detail-image-alternatives-horizontal-small-margin--large: $product-detail-image-alternatives-horizontal-small-margin;
$product-detail-image-alternatives-horizontal-small-padding: rem-calc(0 25);
$product-detail-image-mosaic-height: auto;

@import "@lora/05-components/product/product-detail-image";