$content-calendar-event-tile-title-color: color(primary);
$content-calendar-event-tile-description-color: color(primary-active);
$content-calendar-event-tile-title-font: bold #{rem-calc(14)} / 1 $font-primary;
$content-calendar-event-tile-title-font--large: bold #{rem-calc(18)} / 1 $font-secondary;
$content-calendar-event-tile-description-font: 300 #{rem-calc(14)} / 1 $font-primary;
$content-calendar-event-tile-description-font--large: 300 #{rem-calc(14)} / 1 $font-primary;
$content-calendar-event-tile-caption-border: 1px solid color(dark);
$content-calendar-event-tile-active-caption-background: color(dark);
$content-calendar-event-tile-description-color: color(dark) !default;
$content-calendar-event-tile-active-caption-border: none !default;
$content-calendar-event-tile-active-title-color: color(light) !default;
$content-calendar-event-tile-active-description-color: color(light) !default;
$content-calendar-event-tile-description-width: rem-calc(214);
$content-calendar-event-tile-active-description-width: rem-calc(214);
$content-calendar-event-tile-caption-padding: rem-calc(25 15 20);
$content-calendar-event-tile-caption-padding--large: rem-calc(35 15 22);
$content-calendar-event-tile-active-caption-padding: rem-calc(25 15 20);
$content-calendar-event-tile-active-caption-padding--large: rem-calc(19 15);

@import "@lora/05-components/content/content-calendar-event-tile";