// Arrows
$carousel-arrow-sizes: (
    default: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(40),
        arrow-icon-width: rem-calc(8),
        arrow-icon-height: rem-calc(8),
        arrow-icon: chevron2-right
    ),
    small: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(30),
        arrow-icon-width: rem-calc(6),
        arrow-icon-height: rem-calc(11),
        arrow-icon: chevron-right
    ),
    medium: (
        arrow-width: rem-calc(20),
        arrow-height: rem-calc(24),
        arrow-icon-width: rem-calc(4),
        arrow-icon-height: rem-calc(9),
        arrow-icon: chevron2-right
    )
);
$carousel-item-description-font: 500 #{rem-calc(14)} / 1.4 $font-primary;
$carousel-item-description-font--large: $carousel-item-description-font;
$carousel-item-title-font: 500 #{rem-calc(14)} / 1.4 $font-secondary;
$carousel-item-title-font--large: $carousel-item-title-font;

$carousel-arrow-color: color(dark);
$carousel-arrow-background: color(light);
$carousel-arrow-border: none;

// Pagination
$carousel-pagination-bullet-size: rem-calc(5);
$carousel-pagination-bullet-horizontal-margin: rem-calc(0 10);
$carousel-pagination-bullet-size-default--large: $carousel-pagination-bullet-size;
$carousel-pagination-bullet-horizontal-margin-default--large: $carousel-pagination-bullet-horizontal-margin;
$carousel-pagination-bullet-size-small--large: $carousel-pagination-bullet-size;
$carousel-pagination-bullet-horizontal-margin-small--large: $carousel-pagination-bullet-horizontal-margin;
$carousel-pagination-bullet-color: color(disabled);
$carousel-pagination-bullet-custom-enabled: true;

// Scrollbar
$carousel-scrollbar-padding: 0;
$carousel-scrollbar-height: rem-calc(4);

$carousel-bullet-sizes: (
    default: (
        bullet-size: $carousel-pagination-bullet-size,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin
    ),
    small: (
        bullet-size: rem-calc(7),
        bullet-margin: rem-calc(0 10)
    )
);

$carousel-bullet-sizes--large: (
    default: (
        bullet-size: $carousel-pagination-bullet-size-default--large,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin-default--large
    ),
    small: (
        bullet-size: $carousel-pagination-bullet-size-small--large,
        bullet-margin: $carousel-pagination-bullet-horizontal-margin-small--large
    )
);

$carousel-arrow-hover-theme: (
    light: (
        background: color(dark),
        color: color(light)
    ),
);

@import "@lora/05-components/carousel";