$video-library-item-name-text-transform: none;
$video-library-item-name-text-transform--large: uppercase;
$video-library-item-name-font-family: $font-primary;
$video-library-item-name-font: normal #{rem-calc(14)} / 1.42 $font-primary;
$video-library-item-name-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$video-library-item-description-font--large: normal #{rem-calc(14)} / 1.42 $font-primary;
$video-library-item-name-margin--large: rem-calc(0 0 7 0);
$video-library-item-name-color: color(light);
$video-library-item-name-color--large: color(dark);
$video-library-item-name-letter-spacing: rem-calc(1);
$video-library-item-description-color--large: color(primary);
$video-library-item-description-font-size--large: rem-calc(12);
$video-library-item-description-line-height--large: 1.2;
$video-library-item-description-font-weight--large: 100;
$video-library-item-description-letter-spacing--large: rem-calc(0.5);
$video-library-item-description-text-transform--large: none;
$video-library-item-description-max-lines--large: 2;
$video-library-item-icon-height--large: rem-calc(32);
$video-library-item-icon-width--large: rem-calc(32);
$video-library-item-icon-color: color(secondary);
$video-library-item-icon-background: transparent;
$video-library-item-background--medium-down: color(primary);
$video-library-item-border-radius--medium-down: rem-calc(0);
$video-library-item-margin--medium-down: rem-calc(0 10 0 0);
$video-library-item-padding--medium-down: rem-calc(10);
$video-library-item-width--medium-down: rem-calc(270);
$video-library-playlist-title-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$video-library-playlist-border-top-color: 1px solid color(primary);
$video-library-playlist-margin: rem-calc(0);
$video-library-item-active-border: none;
$video-library-item-active-border--large: none;

@import "@lora/05-components/video-library";