// Deprecated components
@import "../100-deprecated/content-tile-info";

// Content info
$publishing-info-display: $content-tile-info-display !default;
$publishing-info-display--large: $content-tile-info-display--large !default;
$publishing-info-margin: $content-tile-info-margin !default;
$publishing-info-margin--large: $content-tile-info-margin--large !default;
// Content info with image
$publishing-info-image-content-flex-direction: column !default;
$publishing-info-image-content-flex-direction--large: null !default;
$publishing-info-image-date-margin: rem-calc(5 0) !default;
$publishing-info-image-date-margin--large: null !default;
$publishing-info-image-author-margin: 0 !default;
$publishing-info-image-author-margin--large: null !default;
/// Content info image
$publishing-info-image-margin: rem-calc(0 10 0 0) !default;
$publishing-info-image-margin--large: null !default;
$publishing-info-image-flex: 1 0 auto !default;
$publishing-info-image-flex--large: null !default;
$publishing-info-image-border-radius: 50% !default;
/// Content info content
$publishing-info-content-display: flex !default;
$publishing-info-content-display--large: null !default;
$publishing-info-content-flex-direction: column !default;
$publishing-info-content-flex-direction--large: row !default;
$publishing-info-content-letter-spacing: null !default;
/// Content info item
$publishing-info-item-padding: 0 !default;
$publishing-info-item-padding--large: rem-calc(0 0 0 7) !default;
$publishing-info-item-margin: rem-calc(7 0 0) !default;
$publishing-info-item-margin--large: rem-calc(0 0 0 7) !default;
/// Content info item divider
$publishing-info-item-divider: true !default;
$publishing-info-item-divider-height: rem-calc(2) !default;
$publishing-info-item-divider-width: rem-calc(2) !default;
$publishing-info-item-divider-background: color(disabled) !default;
$publishing-info-item-divider-border-radius: 50% !default;
$publishing-info-item-divider-left: 0 !default;
/// Date
$publishing-info-date-font: $content-tile-date-font !default;
$publishing-info-date-font--large: $content-tile-date-font--large !default;
$publishing-info-date-color: $content-tile-date-color !default;
$publishing-info-date-color--large: $content-tile-date-color--large !default;
$publishing-info-date-text-transform: $content-tile-date-text-transform !default;
$publishing-info-date-text-transform--large: $content-tile-date-text-transform--large !default;
$publishing-info-date-margin: $content-tile-date-margin !default;
$publishing-info-date-margin--large: $content-tile-date-margin--large !default;
/// Author
$publishing-info-author-font: $content-tile-author-font !default;
$publishing-info-author-font--large: $content-tile-author-font--large !default;
$publishing-info-author-color: $content-tile-author-color !default;
$publishing-info-author-color--large: $content-tile-author-color--large !default;
$publishing-info-author-text-transform: $content-tile-author-text-transform !default;
$publishing-info-author-text-transform--large: $content-tile-author-text-transform--large !default;
$publishing-info-author-margin: $content-tile-author-margin !default;
$publishing-info-author-margin--large: $content-tile-author-margin--large !default;
/// Author link
$publishing-info-author-link-font: $content-tile-author-link-font !default;
$publishing-info-author-link-font--large: $content-tile-author-link-font--large !default;
$publishing-info-author-link-text-transform: $content-tile-author-link-text-transform !default;
$publishing-info-author-link-text-transform--large: $content-tile-author-link-text-transform--large !default;
$publishing-info-author-link-text-decoration: null !default;

@mixin publishing-info-dark-mode {
    background: dark-mode-color(background);
    color: dark-mode-color(text);

    .c-publishing-info__date,
    .c-publishing-info__author {
        color: dark-mode-color(text);
    }

    .c-publishing-info__item {
        @if ($publishing-info-item-divider) {
            @include breakpoint(large) {
                &::before {
                    background: dark-mode-color(border);
                }
            }
        }
    }
}

@mixin lora-components-publishing-info {
    .c-publishing-info {
        display: $publishing-info-display;
        padding: $publishing-info-margin;

        @include breakpoint(large) {
            display: $publishing-info-display--large;
            padding: $publishing-info-margin--large;
        }

        &.m-image {
            .c-publishing-info__content {
                flex-flow: $publishing-info-image-content-flex-direction;

                @include breakpoint(large) {
                    flex-flow: $publishing-info-image-content-flex-direction--large;
                }
            }

            .c-publishing-info__item {
                margin: 0;
                padding: 0;

                &::before {
                    display: none;
                }
            }

            .c-publishing-info__date {
                @include text-style(
                    $margin: (
                        small: $publishing-info-image-date-margin,
                        large: $publishing-info-image-date-margin--large
                    ),
                );
            }

            .c-publishing-info__author {
                @include text-style(
                    $margin: (
                        small: $publishing-info-image-author-margin,
                        large: $publishing-info-image-author-margin--large
                    ),
                );
            }
        }

        &.m-dark {
            @include publishing-info-dark-mode;
        }
    }

    .c-publishing-info__image {
        margin: $publishing-info-image-margin;
        flex: $publishing-info-image-flex;

        @include breakpoint(large) {
            margin: $publishing-info-image-margin;
            flex: $publishing-info-image-flex--large;
        }

        img {
            border-radius: $publishing-info-image-border-radius;
        }
    }

    .c-publishing-info__content {
        width: 100%;
        display: $publishing-info-content-display;
        flex-flow: $publishing-info-content-flex-direction;
        letter-spacing: $publishing-info-content-letter-spacing;

        @include breakpoint(large) {
            display: $publishing-info-content-display--large;
            flex-flow: $publishing-info-content-flex-direction--large;
        }
    }

    .c-publishing-info__item {
        position: relative;

        &:not(:first-child) {
            padding: $publishing-info-item-padding;
            margin: $publishing-info-item-margin;

            @include breakpoint(large) {
                padding: $publishing-info-item-padding--large;
                margin: $publishing-info-item-margin--large;
            }

            @if ($publishing-info-item-divider) {
                @include breakpoint(large) {
                    &::before { // stylelint-disable-line
                        @include vertical-center;

                        content: '';
                        height: $publishing-info-item-divider-height;
                        width: $publishing-info-item-divider-width;
                        background: $publishing-info-item-divider-background;
                        border-radius: $publishing-info-item-divider-border-radius;
                        #{$global-left}: $publishing-info-item-divider-left;
                    }
                }
            }
        }
    }

    .c-publishing-info__date {
        @include text-style(
            $color: (
                small: $publishing-info-date-color,
                large: $publishing-info-date-color--large
            ),
            $font: (
                small: $publishing-info-date-font,
                large: $publishing-info-date-font--large
            ),
            $margin: (
                small: $publishing-info-date-margin,
                large: $publishing-info-date-margin--large
            ),
            $text-transform: (
                small: $publishing-info-date-text-transform,
                large: $publishing-info-date-text-transform--large
            )
        );
    }

    .c-publishing-info__author {
        @include text-style(
            $color: (
                small: $publishing-info-author-color,
                large: $publishing-info-author-color--large
            ),
            $font: (
                small: $publishing-info-author-font,
                large: $publishing-info-author-font--large
            ),
            $margin: (
                small: $publishing-info-author-margin,
                large: $publishing-info-author-margin--large
            ),
            $text-transform: (
                small: $publishing-info-author-text-transform,
                large: $publishing-info-author-text-transform--large
            )
        );

        a {
            @include text-style(
                $color: (
                    small: inherit
                ),
                $font: (
                    small: $publishing-info-author-link-font,
                    large: $publishing-info-author-link-font--large
                ),
                $text-transform: (
                    small: $publishing-info-author-link-text-transform,
                    large: $publishing-info-author-link-text-transform--large
                ),
                $text-decoration: (
                    small: $publishing-info-author-link-text-decoration
                )
            );
        }

        p {
            margin: 0;
            line-height: inherit;
        }
    }
}
