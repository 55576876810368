$product-links-color: color(text-secondary) !default;
$product-links-font-size: rem-calc(12) !default;
$product-links-margin: 0 0 rem-calc(30) !default;
$product-links-padding: 0 !default;
$product-links-padding--large: null !default;
// Link
$product-links-link-font: null !default;
$product-links-link-margin: 0 0 rem-calc(9) !default;
$product-links-link-text-decoration: null !default;
$product-links-caption-color: null !default;
$product-links-caption-font-size: null !default;
$product-links-caption-text-decoration: null !default;
$product-links-link-text-transform: null !default;
// Icon
$product-links-icon-margin: 0 0 0 0 !default;
$product-links-icon-min-width: rem-calc(40) !default;
$product-links-icon-width: rem-calc(20) !default;
$product-links-icon-height: rem-calc(20) !default;
$product-links-icon-color: color(text-secondary) !default;
$product-links-icon-background-size: rem-calc(12) !default;
$product-links-icon-sample-background-size: rem-calc(20) !default;
/// Icon variations
$product-links-icon-sample: makeup !default;
$product-links-icon-sample-usesvg: true !default;
$product-links-icon-gift: gift !default;
$product-links-icon-gift-usesvg: true !default;
$product-links-icon-find-in-store: pin !default;
$product-links-icon-find-in-store-usesvg: true !default;

@mixin lora-components-product-links {
    .c-product-links {
        color: $product-links-color;
        font-size: $product-links-font-size;
        margin: $product-links-margin;
        padding: $product-links-padding;

        @include breakpoint(large) {
            padding: $product-links-padding--large;
        }
    }

    .c-product-links__link {
        align-items: center;
        display: flex;
        cursor: pointer;
        font: $product-links-link-font;
        margin: $product-links-link-margin;
        text-decoration: $product-links-link-text-decoration;
        text-transform: $product-links-link-text-transform;

        &.m-dark {
            color: dark-mode-color(text);

            .c-product-links__icon.m-sample::before {
                @include svg-icon($product-links-icon-sample, dark-mode-color(text), $color-change: true, $usesvg: $product-links-icon-sample-usesvg);
            }
        }
    }

    .c-product-links__icon {
        display: block;
        margin: $product-links-icon-margin;
        min-width: $product-links-icon-min-width;

        &::before {
            background-position: center left;
            background-repeat: no-repeat;
            background-size: $product-links-icon-background-size;
            content: "";
            display: block;
            height: $product-links-icon-height;
            width: $product-links-icon-width;
        }

        &.m-sample {
            &::before {
                @include svg-icon($product-links-icon-sample, $product-links-icon-color, $product-links-icon-sample-background-size, $usesvg: $product-links-icon-sample-usesvg);
            }
        }

        &.m-e-gift {
            &::before {
                @include svg-icon($product-links-icon-gift, $product-links-icon-color, $usesvg: $product-links-icon-gift-usesvg);
            }
        }

        &.m-find-in-store {
            &::before {
                @include svg-icon($product-links-icon-find-in-store, $product-links-icon-color, $usesvg: $product-links-icon-find-in-store-usesvg);
            }
        }
    }

    .c-product-links__caption {
        color: $product-links-caption-color;
        font-size: $product-links-caption-font-size;
        text-decoration: $product-links-caption-text-decoration;
        display: block;
    }
}
