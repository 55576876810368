$video-asset-info-name-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-secondary;
$video-asset-info-name-font--large: $video-asset-info-name-font;
$video-asset-info-name-text-transform: uppercase;
$video-asset-info-name-margin--large: rem-calc(0 0 10);
$video-asset-info-name-letter-spacing: rem-calc(1);
$video-asset-info-description-color: color(dark);
$video-asset-info-name-color: $video-asset-info-description-color;
$video-asset-info-description-letter-spacing: rem-calc(0.5);
$video-asset-info-description-text-transform: uppercase;
$video-asset-icon-width: rem-calc(32);
$video-asset-icon-height: rem-calc(32);
$video-asset-icon-color: color(dark);
$video-asset-icon-background: transparent;

@import "@lora/05-components/video-asset";